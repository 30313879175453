import { IApp } from "src/types/user";
import styles from "./AppItem.module.scss";
import { useEffect, useState } from "react";
import cl from "classnames";
import { useIntl } from "react-intl";
import { observer } from "mobx-react-lite";

interface IAppItem {
	app: IApp;
}

export const AppItem: React.FC<IAppItem> = observer(({ app }) => {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [isHover, setIsHover] = useState<boolean>(false);
	const intl = useIntl();

	const arrow = (
		<svg
			width="14"
			height="9"
			viewBox="0 0 14 9"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M13 1L7 7L1 1" stroke="white" stroke-width="2" />
		</svg>
	);

	return (
		<div
			className={cl(styles.content, {
				[styles.contentActive]: isOpen,
				[styles.contentHover]: isHover,
			})}
			onMouseLeave={() => {
				setIsHover(false);
			}}
		>
			<img
				src={app.image}
				alt=""
				className={styles.image}
				onMouseEnter={() => {
					setIsOpen(false);
					setIsHover(true);
				}}
			/>
			{isHover && (
				<div
					className={cl(styles.button, styles.buttonHover)}
					onClick={() => window.open(`${app.link}`)}
				>
					{intl.formatMessage({ id: "Launch" })}
				</div>
			)}
			<div
				className={cl(styles.mainContent, {
					[styles.mainContentActive]: isOpen,
					[styles.none]: isHover,
				})}
			>
				<div className={styles.top}>
					<div>{app.name}</div>
					{!isOpen && (
						<div
							className={cl(styles.arrow, { [styles.active]: isOpen })}
							onClick={() => setIsOpen(!isOpen)}
						>
							{arrow}
						</div>
					)}
				</div>
				<div className={styles.description}>
					{isOpen ? app.description : app.description.slice(0, 73) + "..."}
				</div>
				<div
					className={styles.button}
					onClick={() => window.open(`${app.link}`)}
				>
					{intl.formatMessage({ id: "Launch" })}
				</div>
				<div
					className={cl(styles.arrow, { [styles.active]: isOpen })}
					onClick={() => setIsOpen(!isOpen)}
				>
					{arrow}
				</div>
			</div>
		</div>
	);
});
