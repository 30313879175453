import { useIntl } from "react-intl";
import styles from "./DeleteFile.module.scss";
import cl from "classnames";
import { modal } from "../Modal/ModalConnector";
import { useContext } from "react";
import { Context } from "src";
import trashIcon from "../../assets/img/svg/trashIcon.svg";
import { modalUploading } from "../ModalUploading";
import { UploadFile } from "../UploadFile/UploadFile";

interface IDeleteFile {
	object: string;
}

export const DeleteFile: React.FC<IDeleteFile> = ({ object }) => {
	const intl = useIntl();
	const { storeUser } = useContext(Context);

	return (
		<div className={styles.content}>
			<div className={styles.title}>
				{intl.formatMessage({ id: "Confirm Deletion" })}
			</div>
			<div className={styles.mainContent}>
				<div className={styles.text}>
					{intl.formatMessage({ id: "Deletion Settings" })}
				</div>
				<div className={styles.lockBlock}>
					<img src={trashIcon} alt="" />
					<div className={styles.lockBlockText}>
						<div>{intl.formatMessage({ id: "Irreversible" })}</div>
						<div>
							{intl.formatMessage({
								id: "The file will be deleted completely with no delay",
							})}
						</div>
					</div>
				</div>
				<div className={styles.text}>
					{intl.formatMessage({ id: "Do you want to delete this file?" })}
				</div>
				<div className={styles.link}>
					<div>
						{storeUser.objects.find((i) => i.address.objectId === object)?.name}
					</div>
				</div>
			</div>
			<div className={styles.btns}>
				<div className={styles.btnPassive} onClick={() => modal.close()}>
					{intl.formatMessage({ id: "Cancel" })}
				</div>
				<div
					className={styles.btnActive}
					onClick={async () => {
						const res = await storeUser.deleteFile(object);
						if (res) {
							modalUploading.open(
								<UploadFile
									mode={"delete"}
									name={
										storeUser.objects.find((i) => i.address.objectId === object)
											?.name
									}
								/>,
								{ className: "none", abilityHide: true }
							);
							setTimeout(() => {
								modalUploading.close();
							}, 4000);
						}
					}}
				>
					{intl.formatMessage({ id: "Delete" })}
				</div>
			</div>
		</div>
	);
};
