import { Routes, Route } from "react-router-dom";
import { MainPage } from "../../pages/MainPage/MainPage";
import { Header } from "../../components/Header/Header";
import { ControlPanel } from "../../pages/ControlPanel/ControlPanel";
import { WelcomePage } from "../../pages/WelcomePage/WelcomePage";
import PrivateRoute from "./PrivateRoute";
import { AllFiles } from "src/pages/AllFiles/AllFiles";
import { Footer } from "src/components/Footer/Footer";
import { Apps } from "src/pages/Apps/Apps";
import { AppsPage } from "src/pages/AppsPage/AppsPage";

export function AppRoutes() {
	return (
		<Routes>
			<Route
				path="/"
				element={
					<>
						<Header />
						<MainPage />
						<Footer />
					</>
				}
			/>
			<Route
				path="/apps"
				element={
					<>
						<Header />
						<AppsPage />
						<Footer />
					</>
				}
			/>
			<Route
				path="/panel"
				element={
					<>
						<PrivateRoute>
							<ControlPanel />
						</PrivateRoute>
					</>
				}
			/>
			<Route
				path="/allFiles"
				element={
					<>
						<PrivateRoute>
							<AllFiles />
						</PrivateRoute>
					</>
				}
			/>
			<Route
				path="/panelApps"
				element={
					<>
						<PrivateRoute>
							<Apps />
						</PrivateRoute>
					</>
				}
			/>
			<Route
				path="/welcome"
				element={
					<>
						<WelcomePage />
					</>
				}
			/>
		</Routes>
	);
}
