import { HashRouter } from "react-router-dom";
import "./App.css";
import { AppRoutes } from "./routes/AppRoutes";
import { useContext, useEffect } from "react";
import { Context } from "src";
import { ModalConnector } from "src/components/Modal/ModalConnector";
import { observer } from "mobx-react-lite";
import locales from "../assets/i18n";
import { IntlProvider } from "react-intl";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { ModalUploadingConnector } from "src/components/ModalUploading";
import { IAuth } from "src/types/user";

function App() {
	const { storeUser } = useContext(Context);

	useEffect(() => {
		const checkAuth = async () => {
			if (localStorage.getItem("accessToken")) {
				try {
					await storeUser.checkAuth();
					console.log(
						"typeAuthorization",
						localStorage.getItem("typeAuthorization")
					);
				} catch (e) {
					console.log(e);
				}
			}
		};
		checkAuth();
	}, []);

	// For translations into languages
	const messages = {
		...locales.en,
		...locales[storeUser.language],
	};

	return (
		<IntlProvider locale={storeUser.language} messages={messages}>
			<HashRouter>
				<ToastContainer position="top-center" />
				<AppRoutes />
				<ModalConnector />
				<ModalUploadingConnector />
			</HashRouter>
		</IntlProvider>
	);
}

export default observer(App);
