import styles from "./WelcomePage.module.scss";
import neoboxText from "../../assets/img/svg/neoboxText.svg";
import neobox from "../../assets/img/svg/neobox.svg";
import button from "../../assets/img/png/button.png";
import caseImage from "../../assets/img/png/case.png";
import key from "../../assets/img/png/keyYellow.png";
import laptopOrange from "../../assets/img/png/laptopOrange.png";
import lightblubBlue from "../../assets/img/png/lightblubBlue.png";
import lock from "../../assets/img/png/lock.png";
import phone from "../../assets/img/png/phone.png";
import settings from "../../assets/img/png/settings.png";
import diagramPurple from "../../assets/img/png/diagramPurple.png";
import neo from "../../assets/img/svg/neo.svg";
import cl from "classnames";
import { observer } from "mobx-react-lite";
import { useIntl } from "react-intl";
import { SocialNetworks } from "src/components/SocialNetworks/SocialNetworks";
import { InputAuth } from "src/components/InputAuth/InputAuth";
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Context } from "src";
import { toast } from "react-toastify";
import { useTimer } from "react-timer-hook";
import { digestMessage } from "src/utils/hashing";
import { CommentingBlock } from "src/components/CommentingBlock/CommentingBlock";
import {
	fetchCheckEmailCode,
	fetchCheckNickname,
	fetchGetNonce,
	fetchRegister,
	fetchSendEmailCode,
} from "src/app/api/app";
import { useWallet as useWalletNeo } from "@rentfuse-labs/neo-wallet-adapter-react";
import { getNeoLineWallet } from "@rentfuse-labs/neo-wallet-adapter-wallets";
import { IAuth, ISignedData } from "src/types/user";
import { InstallWallet } from "src/components/InstallWallet/InstallWallet";
import { modal } from "src/components/Modal/ModalConnector";
import { getBcInfo } from "src/utils/crypto/bcInfo";
import { getEthereumAccount } from "src/utils/crypto/metamask/metamask";
import { getChainIdFromAuthType } from "src/utils/crypto/getChainIdFromAuthType";

export const WelcomePage = observer(() => {
	const [email, setEmail] = useState<string>("");
	const [password, setPassword] = useState("");
	const [retryPassword, setRetryPassword] = useState<string>("");
	const [code, setCode] = useState<string>("");
	const [nickname, setNickname] = useState<string>("");
	const [error, setError] = useState<React.ReactNode | null | any>(null);
	const [stepRegister, setStepRegister] = useState<
		"Entering email" | "Entering code" | "Data entry" | "Authorize"
	>("Entering email");
	const navigate = useNavigate();
	const intl = useIntl();
	const { storeUser } = useContext(Context);

	const REG_EXP_CHECK_EMAIL =
		/^[A-Za-z\d+_.-]+@[A-Za-z\d_.-]+\.+[A-Za-z]{2,4}$/i;
	const REG_EXP_CHECK_EMAIL_PROD =
		/^[A-Za-z\d_.-]+@[A-Za-z\d_.-]+\.+[A-Za-z]{2,4}$/i;
	const REG_EXP_CHECK_PASSWORD = /(?=.*\d)(?=.*[a-z]).{8,}/;
	const REG_NICKNAME = /[^A-Za-z0-9_]/gi;

	const ERROR_INCORRECT_EMAIL = (
		<>
			{intl.formatMessage({
				id: "Enter the correct email address. For example, example@gmail.com",
			})}
		</>
	);

	const WAITING_EMAIL_CODE = intl.formatMessage({
		id: "We sent a 4-digit verification code to your email. Don’t forget to check the spam folder.",
	});
	const ERROR_INCORRECT_CODE = intl.formatMessage({
		id: "Unfortunately, this code does not fit.",
	});
	const ERROR_INCORRECT_PASSWORDS = intl.formatMessage({
		id: "Please make sure to create a password which is at least 8 characters and contains a digit. Both passwords must be equal.",
	});
	const ERROR_INCORRECT_PASSWORD = intl.formatMessage({
		id: "Please make sure to create a password which is at least 8 characters and contains a digit.",
	});
	const ERROR_EXISTING_NICKNAME = (
		<>
			{intl.formatMessage({
				id: "Oops... This name is already taken.",
			})}{" "}
			{/* <Link to={"/authorization"}>{intl.formatMessage({ id: "Log in" })}</Link>{" "} */}
			{/* {intl.formatMessage({ id: "or use another name." })} */}
		</>
	);

	const BACK_ERROR_MESSAGE_RESPONSE = "Error - user not exist";

	const funcCheckNickname = async (nickname: string) => {
		if (!(await checkNickname(nickname))) {
			setError(ERROR_EXISTING_NICKNAME);
		} else {
			setError(null);
		}
	};

	const checkNickname = async (nickname: string) => {
		const res = await (await fetchCheckNickname(nickname)).data.count;
		return !(Boolean(res) || nickname.toLocaleLowerCase().includes("pixudi"));
	};

	const statusEmail = (): "error" | "done" | "waiting" | undefined => {
		switch (stepRegister) {
			case "Entering email":
				if (email === "") {
					return "waiting";
				} else if (String(error) === String(ERROR_INCORRECT_EMAIL)) {
					return "error";
				} else {
					return "done";
				}
			case "Entering code":
				return "waiting";
			case "Data entry":
			case "Authorize":
				return "done";
		}
	};

	const onSubmit = async (event: any) => {
		if (localStorage.getItem("accessToken")) {
			await storeUser.logout();
		}
		event.preventDefault();
		const REG_EMAIL =
			process.env.REACT_APP_MODE === "dev" ||
			process.env.REACT_APP_MODE === "local"
				? REG_EXP_CHECK_EMAIL
				: REG_EXP_CHECK_EMAIL_PROD;
		switch (stepRegister) {
			case "Entering email":
				if (!REG_EMAIL.test(email)) {
					setError(ERROR_INCORRECT_EMAIL);
				} else {
					let check = await checkNickname(email);
					if (!check) {
						setStepRegister("Authorize");
					} else {
						await funcSendEmailCode();
					}
				}
				break;
			case "Authorize":
				const res = await storeUser.loginEmail(
					IAuth.AuthTypes.email,
					email,
					password
				);
				console.log(res);
				if (res.errorMessage === BACK_ERROR_MESSAGE_RESPONSE) {
					setError(
						intl.formatMessage({
							id: "Wrong email or password. Please check your data.",
						})
					);
				} else {
					navigate("/panel");
				}
				break;
			case "Entering code":
				const resCode = await fetchCheckEmailCode(email, Number(code)!);
				console.log(resCode);
				if (resCode.data) {
					setStepRegister("Data entry");
					setError(null);
				} else {
					setError(ERROR_INCORRECT_CODE);
				}
				break;
			case "Data entry":
				if (!error) {
					try {
						const hash = await digestMessage(password);
						const res = await fetchRegister(
							IAuth.AuthTypes.email,
							email,
							hash,
							email
						);
						if (res.data.result) {
							const resLogin = await storeUser.loginEmail(
								IAuth.AuthTypes.email,
								email,
								password
							);
							if (resLogin) {
								navigate("/panel");
							}
						}
					} catch {
						toast.error(intl.formatMessage({ id: "Something went wrong" }));
					}
				}
				break;
		}
	};

	const runningTime = new Date();
	runningTime.setSeconds(runningTime.getSeconds() + 90);
	const timer = useTimer({
		autoStart: false,
		expiryTimestamp: runningTime,
		onExpire: () => console.warn("onExpire called"),
	});

	const funcSendEmailCode = async () => {
		const res = await fetchSendEmailCode(email);
		if (res.data) {
			setStepRegister("Entering code");
			const runningTime = new Date();
			runningTime.setSeconds(runningTime.getSeconds() + 90);
			timer.restart(runningTime);
			setError(null);
		} else {
			setStepRegister("Authorize");
			//setError(ERROR_EXISTING_EMAIL);
		}
	};

	const disabledMainButton = () => {
		switch (stepRegister) {
			case "Entering email":
				return Boolean(error);
			case "Authorize":
				return password === "" || error !== null;
			case "Entering code":
				return code === "";
			case "Data entry":
				return (
					error !== null ||
					//nickname === "" ||
					password === "" ||
					retryPassword === "" ||
					retryPassword !== password
				);
		}
	};

	useEffect(() => {
		if (password !== retryPassword && retryPassword !== "") {
			setError(ERROR_INCORRECT_PASSWORD);
		}
	}, [password, retryPassword]);

	//NEOLine
	const neoLine = useWalletNeo();

	const authNeoLine = async () => {
		if (localStorage.getItem("accessToken")) {
			await storeUser.logout();
		}
		console.log(neoLine);
		if (window.NEOLineN3 === undefined) {
			modal.open(<InstallWallet />, { className: "none" });
			// 	alert("NEOLine Wallet is not installed");
		} else {
			try {
				const neoline = new window.NEOLineN3.Init();
				neoLine.wallet = getNeoLineWallet();
				const adapter = neoLine.wallet.adapter();
				neoLine.adapter = adapter;
				await neoLine.adapter.connect();
				console.log(adapter, neoLine);
				let account = await neoline.getAccount();
				console.log("account", account);
				let randomSalt = String(Math.random() * 99999999);
				console.log("randomSalt", randomSalt);
				const publicKey = await neoline.getPublicKey();
				console.log("publicKey", publicKey);
				const resNonce = await fetchGetNonce(
					IAuth.AuthTypes.neo,
					publicKey.publicKey
				);
				console.log("resNonce", resNonce);
				const signedData: ISignedData = await neoline.signMessage({
					message: String(resNonce.data.nonce),
				});
				console.log("signedData", signedData);

				if (account.address !== "") {
					const res = await storeUser.login(signedData, account.address);
					if (res) {
						navigate("/panel");
					}
				} else {
					alert("Log in to the extension!");
				}
			} catch (er) {
				console.error("Something went wrong", er);
			}
		}
	};

	const login = async (type: IAuth.AuthTypes, idUser: string) => {
		if (localStorage.getItem("accessToken")) {
			await storeUser.logout();
		}
		if (
			type === IAuth.AuthTypes.velas ||
			type === IAuth.AuthTypes.moonbeam ||
			type === IAuth.AuthTypes.oasis ||
			type === IAuth.AuthTypes.avax ||
			(type === IAuth.AuthTypes.doge && !idUser.includes("cosmos")) ||
			type === IAuth.AuthTypes.skale ||
			type === IAuth.AuthTypes.ever ||
			type === IAuth.AuthTypes.coredao ||
			type === IAuth.AuthTypes.arthera ||
			type === IAuth.AuthTypes.pixudi ||
			type === IAuth.AuthTypes.bnb ||
			type === IAuth.AuthTypes.base ||
			type === IAuth.AuthTypes.neox
		) {
			console.log("address", idUser);
			const res = await storeUser.loginMetaMask(type, idUser);

			console.log("res", res);
			if (res === true) {
				navigate("/panel");
			} else {
				toast.error("Something went wrong");
			}
		}
	};

	//METAMASK
	const authMetamask = async (walletType: IAuth.AuthTypes) => {
		const account = await getEthereumAccount(
			getChainIdFromAuthType(walletType)
		);
		if (account) {
			console.log(account.provider, account.signer, account.walletAddress);
			login(walletType, account.walletAddress.toLowerCase());
		}
	};

	return (
		<form className={styles.wrapper} onSubmit={onSubmit}>
			<div className={styles.content}>
				<div className={styles.titleBlock}>
					<div>{intl.formatMessage({ id: "Welcome to" })}</div>
					<div className={styles.titleSvg}>
						<img src={neobox} alt="" />
						<img src={neoboxText} alt="" />
					</div>
				</div>
				{stepRegister === "Entering email" && <SocialNetworks />}
				{stepRegister === "Entering email" && (
					<div className={styles.wOr}>
						<svg
							width="94"
							height="1"
							viewBox="0 0 94 1"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<line
								x1="2.16256e-08"
								y1="0.5"
								x2="94"
								y2="0.500017"
								stroke="white"
								stroke-opacity="0.2"
							/>
						</svg>
						{intl.formatMessage({ id: "or" })}
						<svg
							width="94"
							height="1"
							viewBox="0 0 94 1"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<line
								x1="2.16256e-08"
								y1="0.5"
								x2="94"
								y2="0.500017"
								stroke="white"
								stroke-opacity="0.2"
							/>
						</svg>
					</div>
				)}
				{stepRegister === "Entering email" && (
					<div className={styles.buttonNeo} onClick={() => authNeoLine()}>
						<img src={neo} alt="" />
						<div>
							{intl
								.formatMessage({ id: "PIX10 Wallet" })
								.replace("PIX10", "Neo")}
						</div>
					</div>
				)}
				{stepRegister === "Entering email" && (
					<div className={styles.wOr}>
						<svg
							width="94"
							height="1"
							viewBox="0 0 94 1"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<line
								x1="2.16256e-08"
								y1="0.5"
								x2="94"
								y2="0.500017"
								stroke="white"
								stroke-opacity="0.2"
							/>
						</svg>
						{intl.formatMessage({ id: "or" })}
						<svg
							width="94"
							height="1"
							viewBox="0 0 94 1"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<line
								x1="2.16256e-08"
								y1="0.5"
								x2="94"
								y2="0.500017"
								stroke="white"
								stroke-opacity="0.2"
							/>
						</svg>
					</div>
				)}
				{stepRegister === "Entering email" && (
					<div className={styles.mainNetworksWrapper}>
						<div className={styles.mainNetworks}>
							<div className={styles.column}>
								<div
									className={styles.wallet}
									onClick={() => authMetamask(IAuth.AuthTypes.base)}
								>
									<div className={styles.imageBlock}>
										<img
											src={getBcInfo(IAuth.AuthTypes.base, "")!.icon}
											alt=""
										/>
										<div>Base</div>
									</div>
								</div>
							</div>
							<div className={styles.column}>
								<div
									className={styles.wallet}
									onClick={() => authMetamask(IAuth.AuthTypes.neox)}
								>
									<div className={styles.imageBlock}>
										<img
											src={getBcInfo(IAuth.AuthTypes.neox, "")!.icon}
											alt=""
										/>
										<div>Neo X</div>
									</div>
								</div>
							</div>
						</div>
						<div className={styles.mainNetworks}>
							<div className={styles.column}>
								<div
									className={styles.wallet}
									onClick={() => authMetamask(IAuth.AuthTypes.bnb)}
								>
									<div className={styles.imageBlock}>
										<img
											src={getBcInfo(IAuth.AuthTypes.bnb, "")!.icon}
											alt=""
										/>
										<div>BNB</div>
									</div>
								</div>
							</div>
							<div className={styles.column}>
								<div
									className={styles.wallet}
									onClick={() => authMetamask(IAuth.AuthTypes.avax)}
								>
									<div className={styles.imageBlock}>
										<img
											src={getBcInfo(IAuth.AuthTypes.avax, "")!.icon}
											alt=""
										/>
										<div>Avalanche</div>
									</div>
								</div>
							</div>
						</div>
						{/* <div className={styles.column}> */}
						{/*<div*/}
						{/*	className={styles.wallet}*/}
						{/*	onClick={() => authMetamask(IAuth.AuthTypes.pixudi)}*/}
						{/*>*/}
						{/*	<div className={styles.imageBlock}>*/}
						{/*		<img*/}
						{/*			src={getBcInfo(IAuth.AuthTypes.pixudi, "")!.icon}*/}
						{/*			alt=""*/}
						{/*		/>*/}
						{/*		<div>Pixudi Chain</div>*/}
						{/*	</div>*/}
						{/*</div>*/}
						{/*<div*/}
						{/*	className={styles.wallet}*/}
						{/*	onClick={() => authMetamask(IAuth.AuthTypes.oasis)}*/}
						{/*>*/}
						{/*	<div className={styles.imageBlock}>*/}
						{/*		<img*/}
						{/*			src={getBcInfo(IAuth.AuthTypes.oasis, "")!.icon}*/}
						{/*			alt=""*/}
						{/*		/>*/}
						{/*		<div>Oasis</div>*/}
						{/*	</div>*/}
						{/*</div>*/}
						{/* </div> */}
						{/*<div className={styles.column}>*/}
						{/*	<div*/}
						{/*		className={styles.wallet}*/}
						{/*		onClick={() => authMetamask(IAuth.AuthTypes.base)}*/}
						{/*	>*/}
						{/*		<div className={styles.imageBlock}>*/}
						{/*			<img*/}
						{/*				src={getBcInfo(IAuth.AuthTypes.base, "")!.icon}*/}
						{/*				alt=""*/}
						{/*			/>*/}
						{/*			<div>Base</div>*/}
						{/*		</div>*/}
						{/*	</div>*/}
						{/*	<div*/}
						{/*		className={styles.wallet}*/}
						{/*		onClick={() => authMetamask(IAuth.AuthTypes.skale)}*/}
						{/*	>*/}
						{/*		<div className={styles.imageBlock}>*/}
						{/*			<img*/}
						{/*				src={getBcInfo(IAuth.AuthTypes.skale, "")!.icon}*/}
						{/*				alt=""*/}
						{/*			/>*/}
						{/*			<div>SKALE</div>*/}
						{/*		</div>*/}
						{/*	</div>*/}
						{/*	<div*/}
						{/*		className={styles.wallet}*/}
						{/*		onClick={() => authMetamask(IAuth.AuthTypes.arthera)}*/}
						{/*	>*/}
						{/*		<div className={styles.imageBlock}>*/}
						{/*			<img*/}
						{/*				src={getBcInfo(IAuth.AuthTypes.arthera, "")!.icon}*/}
						{/*				alt=""*/}
						{/*			/>*/}
						{/*			<div>Arthera</div>*/}
						{/*		</div>*/}
						{/*	</div>*/}
						{/*	<div*/}
						{/*		className={styles.wallet}*/}
						{/*		onClick={() => authMetamask(IAuth.AuthTypes.velas)}*/}
						{/*	>*/}
						{/*		<div className={styles.imageBlock}>*/}
						{/*			<img*/}
						{/*				src={getBcInfo(IAuth.AuthTypes.velas, "")!.icon}*/}
						{/*				alt=""*/}
						{/*			/>*/}
						{/*			<div>Velas</div>*/}
						{/*		</div>*/}
						{/*	</div>*/}
						{/*</div>*/}
					</div>
				)}
				{stepRegister === "Entering email" && (
					<div className={styles.wOr}>
						<svg
							width="94"
							height="1"
							viewBox="0 0 94 1"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<line
								x1="2.16256e-08"
								y1="0.5"
								x2="94"
								y2="0.500017"
								stroke="white"
								stroke-opacity="0.2"
							/>
						</svg>
						{intl.formatMessage({ id: "or" })}
						<svg
							width="94"
							height="1"
							viewBox="0 0 94 1"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<line
								x1="2.16256e-08"
								y1="0.5"
								x2="94"
								y2="0.500017"
								stroke="white"
								stroke-opacity="0.2"
							/>
						</svg>
					</div>
				)}
				<div className={styles.inputs}>
					{(stepRegister === "Entering code" ||
						stepRegister === "Entering email" ||
						stepRegister === "Authorize" ||
						stepRegister === "Data entry") && (
						<div className={styles.input}>
							<InputAuth
								onChange={(e) =>
									setEmail(e.target.value.replace(/[^A-Za-z0-9!_+@.-]/gi, ""))
								}
								label={intl.formatMessage({ id: "Email Address" })}
								value={email}
								setValue={setEmail}
								setError={setError}
								regExp={
									process.env.REACT_APP_MODE === "dev" ||
									process.env.REACT_APP_MODE === "local"
										? REG_EXP_CHECK_EMAIL
										: REG_EXP_CHECK_EMAIL_PROD
								}
								textError={ERROR_INCORRECT_EMAIL}
								statusIcon={statusEmail()}
								disabled={
									stepRegister === "Entering code" ||
									stepRegister === "Data entry" ||
									stepRegister === "Authorize"
								}
								placeholder={intl.formatMessage({ id: "Email Address" })}
							/>
						</div>
					)}
					{stepRegister === "Authorize" && (
						<div className={styles.input} style={{ marginTop: "24px" }}>
							<InputAuth
								label={intl.formatMessage({
									id: "Password",
								})}
								setError={setError}
								setValue={setPassword}
								value={password}
								type={"password"}
								statusIcon={
									password === ""
										? "waiting"
										: String(error) === String(ERROR_INCORRECT_PASSWORD)
										? "error"
										: "done"
								}
								regExp={REG_EXP_CHECK_PASSWORD}
								textError={ERROR_INCORRECT_PASSWORD}
								placeholder={intl.formatMessage({ id: "Enter your password" })}
							/>
						</div>
					)}
					{stepRegister === "Entering code" && (
						<div className={styles.input} style={{ marginTop: "24px" }}>
							<InputAuth
								onChange={(e) => {
									error !== null && setError(null);
									setCode(e.target.value);
								}}
								label={intl.formatMessage({
									id: "Verification Code",
								})}
								value={code}
								setValue={setCode}
								type="number"
								statusIcon={
									error === ERROR_INCORRECT_CODE ? "error" : undefined
								}
							/>
						</div>
					)}
					{stepRegister === "Data entry" && (
						<>
							{/* <div className={styles.input}>
								<InputAuth
									label="Choose Username"
									value={nickname}
									setValue={(v) => {}}
									onInput={(e) => {
										setNickname(
											e.currentTarget.value
												.replace(REG_NICKNAME, "")
												.slice(0, 21)
										);
										funcCheckNickname(
											e.currentTarget.value.replace(REG_NICKNAME, "")
										);
									}}
									statusIcon={
										String(error) === String(ERROR_EXISTING_NICKNAME)
											? "error"
											: nickname
											? "done"
											: "waiting"
									}
								/>
							</div> */}
							<div className={styles.input} style={{ marginTop: "24px" }}>
								<InputAuth
									label={intl.formatMessage({
										id: "Set Password",
									})}
									value={password}
									setValue={setPassword}
									setError={setError}
									type="password"
									statusIcon={
										password === ""
											? "waiting"
											: String(error) === String(ERROR_INCORRECT_PASSWORD) &&
											  retryPassword === ""
											? "error"
											: "done"
									}
									regExp={REG_EXP_CHECK_PASSWORD}
									textError={ERROR_INCORRECT_PASSWORDS}
									id={"input1"}
								/>
								<div className={styles.checkInput}></div>
							</div>
							<div className={styles.input} style={{ marginTop: "24px" }}>
								<InputAuth
									label={intl.formatMessage({
										id: "Retry Password",
									})}
									value={retryPassword}
									setValue={setRetryPassword}
									setError={setError}
									type="password"
									statusIcon={
										retryPassword === ""
											? "waiting"
											: password !== retryPassword ||
											  String(error) === String(ERROR_INCORRECT_PASSWORD)
											? "error"
											: "done"
									}
									regExp={REG_EXP_CHECK_PASSWORD}
									textError={ERROR_INCORRECT_PASSWORDS}
									id={"input2"}
								/>
								<div className={styles.checkInput}></div>
							</div>
						</>
					)}
				</div>
				{stepRegister === "Authorize" &&
					error !== null &&
					email !== "" &&
					password !== "" && (
						<CommentingBlock className={styles.comment} type={"error"}>
							{error}
						</CommentingBlock>
					)}
				{stepRegister !== "Data entry" &&
					stepRegister !== "Authorize" &&
					error !== null &&
					email !== "" && (
						<CommentingBlock className={styles.comment} type={"error"}>
							{error}
						</CommentingBlock>
					)}
				{stepRegister === "Data entry" &&
					error !== null &&
					(password !== "" || retryPassword !== "") && (
						<CommentingBlock className={styles.comment} type={"error"}>
							{error}
						</CommentingBlock>
					)}
				{stepRegister === "Entering code" && !error && (
					<CommentingBlock className={styles.comment} type={"waiting"}>
						{WAITING_EMAIL_CODE}
					</CommentingBlock>
				)}
				<div className={styles.btns}>
					<button
						className={cl(styles.btn, {
							[styles.disabled]: disabledMainButton(),
						})}
						type="submit"
					>
						{stepRegister === "Data entry"
							? intl.formatMessage({ id: "Agree and Register" })
							: intl.formatMessage({ id: "Continue" })}
					</button>
					{stepRegister === "Entering code" && (
						<button
							type="button"
							disabled={timer.isRunning}
							onClick={() => {
								funcSendEmailCode();
							}}
							className={cl(styles.btnPassive, {
								[styles.disabled]: timer.isRunning,
							})}
						>
							{intl.formatMessage({
								id: "Send Again",
							})}
							{timer.isRunning && (
								<div className={styles.timer}>
									0{timer.minutes} :{" "}
									{timer.seconds >= 0 && timer.seconds < 10 && "0"}
									{timer.seconds}
								</div>
							)}
						</button>
					)}
					{stepRegister === "Data entry" && (
						<div className={styles.termsOfUse}>
							{intl.formatMessage({
								id: "By clicking ‘Accept and Register’, you agree to",
							})}{" "}
							<span>{intl.formatMessage({ id: "Terms of Use" })}</span>{" "}
							{intl.formatMessage({ id: "of NEOBOX" })}
						</div>
					)}
				</div>
				{stepRegister === "Authorize" && (
					<div
						className={styles.forgotPassword}
						// onClick={() =>
						// 	toast.success(
						// 		intl.formatMessage({ id: "Password sent to your email" })
						// 	)
						// }
					>
						{/* {intl.formatMessage({ id: "Forgot your password?" })} */}
					</div>
				)}
			</div>
			<div className={styles.icons}>
				<img src={button} alt="" className={styles.button} />
				<img src={caseImage} alt="" className={styles.caseImage} />
				<img src={diagramPurple} alt="" className={styles.diagram} />
				<img src={key} alt="" className={styles.key} />
				<img src={laptopOrange} alt="" className={styles.laptopOrange} />
				<img src={lightblubBlue} alt="" className={styles.lightblubBlue} />
				<img src={lock} alt="" className={styles.lock} />
				<img src={phone} alt="" className={styles.phone} />
				<img src={settings} alt="" className={styles.settings} />
			</div>
		</form>
	);
});
