import { Sidebar } from "../../components/Sidebar/Sidebar";
import styles from "./ControlPanel.module.scss";
import importBig from "../../assets/img/svg/importBig.svg";
import unlock from "../../assets/img/svg/unlock.svg";
import arrow from "../../assets/img/svg/arrow.svg";
import { useContext } from "react";
import { Context } from "src";
import { observer } from "mobx-react-lite";
import cl from "classnames";
import { ConfirmUpload } from "src/components/ConfirmUpload/ConfirmUpload";
import { modal } from "src/components/Modal/ModalConnector";
import { useIntl } from "react-intl";
import { ControlPanelTop } from "src/components/ControlPanelTop/ControlPanelTop";
import { FileItem } from "src/components/FileItem/FileItem";

export const ControlPanel = observer(() => {
	const { storeUser } = useContext(Context);
	const intl = useIntl();

	const _handleImageChange = async (e) => {
		const files = (e.target as HTMLInputElement).files;
		if (files && files[0]) {
			readImage(files[0]);
			console.log(files[0]);
		}
	};

	function readImage(file: File) {
		readFileDataAsBase64(file).then((currentFile) => {
			console.log("file", currentFile);
			console.log(String(currentFile));
			modal.open(<ConfirmUpload file={file} image={String(currentFile)} />, {
				className: "none",
			});
		});
	}

	const readFileDataAsBase64 = (file: any) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();

			reader.onload = (event) => {
				resolve(event.target?.result);
			};

			reader.onerror = (err) => {
				reject(err);
			};

			reader.readAsDataURL(file);
		});
	};

	return (
		<div className={styles.wrapperPanel}>
			<Sidebar />
			<div className={styles.content}>
				<ControlPanelTop />
				<div className={styles.workarea}>
					<div className={styles.workareaTop}>
						<div className={styles.files}>
							<div>{intl.formatMessage({ id: "All files" })}</div>
							<img src={arrow} alt="" />
						</div>
						<div className={styles.unlock}>
							<img src={unlock} alt="" />
							<div>{intl.formatMessage({ id: "Everyone can see" })}</div>
						</div>
					</div>
					{storeUser.files.length === 0 && (
						<div className={styles.uploadArea}>
							<div
								className={cl(styles.import, {
									[styles.disabled]:
										Number(storeUser.user.memory) >
										50 * 10 ** 6 + Number(storeUser.user.additional_memory),
									//||
									//storeUser.user.container === null,
								})}
							>
								<img src={importBig} alt="" />
								<div>
									{intl.formatMessage({ id: "Drop file here to upload" })}
								</div>
								<label htmlFor="upload-photo" className={styles.importButton}>
									{intl.formatMessage({ id: "Choose File" })}
								</label>
								<input
									type="file"
									id="upload-photo"
									onChange={(e) => _handleImageChange(e)}
									style={{ display: "none" }}
								/>
							</div>
						</div>
					)}
				</div>
				<div className={styles.items}>
					{storeUser.files
						.slice()
						.sort(function (a, b) {
							let findA = storeUser.objects.find(
								(i) =>
									i.address.containerId === a.container &&
									i.address.objectId === a.object
							)?.name;
							let findB = storeUser.objects.find(
								(i) =>
									i.address.containerId === b.container &&
									i.address.objectId === b.object
							)?.name;
							if (findA && findB && findA > findB) {
								return 1;
							}
							if (findA && findB && findA < findB) {
								return -1;
							}
							return 0;
						})
						.map((item, index) => {
							return <FileItem item={item} />;
						})}
				</div>
			</div>
		</div>
	);
});
