import { useIntl } from "react-intl";
import styles from "./ToastAddressCopied.module.scss";
import ok from "../../assets/img/svg/ok.svg";

export const ToastAddressCopied = () => {
    const intl = useIntl();
    
    return (
        <div className={styles.content}>
            <div>{intl.formatMessage({id: "Address Copied"})}</div>
            <img src={ok} alt="" />
        </div>
    )
}