export const showHidePasswords = (id: string) => {
	let input1 = document.getElementById(id);
	if (input1!.getAttribute("type") === "password") {
		let view = input1!.nextSibling as HTMLElement;
		console.log("view", view);
		view!.classList.add("view");
		input1!.setAttribute("type", "text");
	} else {
		let view = input1!.nextSibling as HTMLElement;
		console.log("view", view);
		view!.classList.remove("view");
		input1!.setAttribute("type", "password");
	}
};

export const showHidePassword = () => {
	let input = document.getElementById("passwordInput");
	let view = document.getElementById("passwordControl");
	if (input!.getAttribute("type") === "password") {
		view!.classList.add("view");
		input!.setAttribute("type", "text");
	} else {
		view!.classList.remove("view");
		input!.setAttribute("type", "password");
	}
};
