import React, { createContext } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./app/App";
import StoreUser from "./store/storeUser";
import "react-toastify/dist/ReactToastify.min.css";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);

const storeUser = new StoreUser();

interface State {
	storeUser: StoreUser;
}

export const Context = createContext<State>({
	storeUser,
});

root.render(
	<Context.Provider
		value={{
			storeUser,
		}}
	>
		<App />
	</Context.Provider>
);
