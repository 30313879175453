import styles from "./UploadFile.module.scss";
import ok from "../../assets/img/svg/okGreen.svg";
import cross from "../../assets/img/svg/crossGrey.svg";
import deleteRed from "../../assets/img/svg/deleteRed.svg";
import { modalUploading } from "../ModalUploading";
import { useIntl } from "react-intl";
import { useContext } from "react";
import { Context } from "src";

interface IUploadFile {
	name?: string;
	size?: string;
	mode: "uploading" | "uploaded" | "delete";
}

export const UploadFile: React.FC<IUploadFile> = ({ name, size, mode }) => {
	const intl = useIntl();
	const { storeUser } = useContext(Context);

	const getTitle = () => {
		switch (mode) {
			case "uploading":
				return <div>{intl.formatMessage({ id: "Uploading 1 file" })}</div>;
			case "uploaded":
				return <div>{intl.formatMessage({ id: "1 file uploaded" })}</div>;
			case "delete":
				return <div>{intl.formatMessage({ id: "1 file deleted" })}</div>;
		}
	};

	const getIcon = () => {
		switch (mode) {
			case "uploading":
				return <span className={styles.loader}></span>;
			case "uploaded":
				return <img src={ok} alt="" />;
			case "delete":
				return <img src={deleteRed} alt="" />;
		}
	};

	return (
		<div className={styles.content}>
			<div className={styles.top}>
				{getTitle()}
				{getIcon()}
				{mode !== "uploading" && (
					<img
						src={cross}
						alt=""
						className={styles.cross}
						onClick={() => modalUploading.close()}
					/>
				)}
			</div>
			<div className={styles.bottom}>
				{name && (
					<div>{name.length > 40 ? name.slice(0, 40) + "..." : name}</div>
				)}
				{size && <div>{size} MB</div>}
			</div>
		</div>
	);
};
