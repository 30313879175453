import { observer } from "mobx-react-lite";
import styles from "./ShareFile.module.scss";
import { useIntl } from "react-intl";
import lockIcon from "../../assets/img/svg/lockIcon.svg";
import shareIcon from "../../assets/img/svg/shareIcon.svg";
import copy from "copy-to-clipboard";
import { toast } from "react-toastify";
import { modal } from "../Modal/ModalConnector";
import { ToastLinkCopied } from "../ToastLinkCopied/ToastLinkCopied";

interface IShareFile {
	link: string;
}

export const ShareFile: React.FC<IShareFile> = observer(({ link }) => {
	const intl = useIntl();

	return (
		<div className={styles.content}>
			<div className={styles.title}>
				{intl.formatMessage({ id: "Share your file" })}
			</div>
			<div className={styles.mainContent}>
				<div className={styles.text}>
					{intl.formatMessage({ id: "Access Settings" })}
				</div>
				<div className={styles.lockBlock}>
					<img src={lockIcon} alt="" />
					<div className={styles.lockBlockText}>
						<div>{intl.formatMessage({ id: "Everyone can see" })}</div>
						<div>
							{intl.formatMessage({
								id: "Anyone with the link can view your file",
							})}
						</div>
					</div>
				</div>
				<div className={styles.text}>
					{intl.formatMessage({ id: "Share Your File" })}
				</div>
				<div className={styles.link}>
					<img
						src={shareIcon}
						alt=""
						onClick={() => {
							window.open(link);
						}}
					/>
					<div>{link.length > 50 ? link.slice(0, 50) + "..." : link}</div>
				</div>
			</div>
			<div className={styles.btns}>
				<div
					className={styles.btnPassive}
					onClick={() => {
						copy(link);
						toast.success(<ToastLinkCopied />, {
							className: "toastAddressCopied",
						});
					}}
				>
					{intl.formatMessage({ id: "Copy Link" })}
				</div>
				<div className={styles.btnActive} onClick={() => modal.close()}>
					{intl.formatMessage({ id: "Done" })}
				</div>
			</div>
		</div>
	);
});
