export enum CHAIN_IDs {
	velasTestnet = 111,
	velas = 106,
	opBnb = 204,
	bnbTestnet = 97,
	avax = 43114,
	avaxTestnet = 43113,
	bnb = 56,
	base = 8453,
	eth = 1,
	// skale = 1351057110,
	//skale = 344106930,
	skale = 1564830818,
	//skaleTestnet = 344106930,
	skaleTestnet = 974399131,
	moonbeam = 1284,
	moonbeamTest = 1287,
	oasis = 23294,
	oasisTestnet = 23295,
	doge = 2000,
	dogeTestnet = 568,
	polygon = 137,
	polygonTestnet = 80001,
	skaleEuropa = 2046399126,
	coredao = 1116,
	coredaoTestnet = 1115,
	arthera = 10242,
	artheraTestnet = 10243,
	pixudi = 10899,
	pixudiTestnet = 10899,
	neoX = 12227331,
}

export const AVALANCHE_MAINNET_PARAMS = {
	chainId: "0x" + Number(CHAIN_IDs.avax).toString(16),
	rpcUrls: ["https://api.avax.network/ext/bc/C/rpc"],
	chainName: "Avalanche C-Chain",
	nativeCurrency: {
		name: "Avalanche",
		symbol: "AVAX",
		decimals: 18,
	},
	blockExplorerUrls: ["https://snowtrace.io/"],
};
export const AVALANCHE_TESTNET_PARAMS = {
	chainId: "0x" + Number(CHAIN_IDs.avaxTestnet).toString(16),
	rpcUrls: ["https://api.avax-test.network/ext/bc/C/rpc"],
	chainName: "Avalanche Fuji Testnet",
	nativeCurrency: {
		name: "Avalanche Fuji Testnet",
		symbol: "AVAX",
		decimals: 18,
	},
	blockExplorerUrls: ["https://testnet.snowtrace.io"],
};
export const VELAS_PARAMS = {
	chainId: "0x" + Number(CHAIN_IDs.velas).toString(16),
	rpcUrls: ["https://evmexplorer.velas.com/rpc"],
	chainName: "Velas EVM Mainnet",
	nativeCurrency: {
		name: "Velas",
		symbol: "VLX",
		decimals: 18,
	},
	blockExplorerUrls: ["https://evmexplorer.velas.com/rpc"],
};

export const BASE_MAINNET_PARAMS = {
	chainId: "0x" + Number(CHAIN_IDs.base).toString(16),
	rpcUrls: ["https://mainnet.base.org"],
	chainName: "Base Mainnet",
	nativeCurrency: {
		symbol: "ETH",
		decimals: 18,
	},
	blockExplorerUrls: ["https://basescan.org"],
};

export const VELAS_TESTNET_PARAMS = {
	chainId: "0x" + Number(CHAIN_IDs.velasTestnet).toString(16),
	rpcUrls: ["https://testnet.velas.com/rpc"],
	chainName: "Velas Testnet",
	nativeCurrency: {
		name: "Velas",
		symbol: "VLX",
		decimals: 18,
	},
	blockExplorerUrls: ["https://evmexplorer.testnet.velas.com"],
};
export const MOONBEAM_PARAMS = {
	chainId: "0x" + Number(CHAIN_IDs.moonbeam).toString(16),
	rpcUrls: ["https://rpc.api.moonbeam.network"],
	chainName: "Moonbeam",
	nativeCurrency: {
		name: "Moonbeam",
		symbol: "GLMR",
		decimals: 18,
	},
	blockExplorerUrls: ["https://moonbeam.moonscan.io"],
};
export const MOONBEAM_TESTNET_PARAMS = {
	chainId: "0x" + Number(CHAIN_IDs.moonbeamTest).toString(16),
	rpcUrls: ["https://rpc.api.moonbase.moonbeam.network"],
	chainName: "Moonbeam Testnet",
	nativeCurrency: {
		name: "Moonbeam",
		symbol: "GLMR",
		decimals: 18,
	},
	blockExplorerUrls: [
		"https://moonbase.moonscan.io",
		"https://moonbase-blockscout.testnet.moonbeam.network",
		"https://moonbeam-explorer.netlify.app/?network=MoonbaseAlpha",
		"https://moonbase.subscan.io/",
		"https://polkadot.js.org/apps/?rpc=wss://wss.api.moonbase.moonbeam.network#/explorer",
	],
};
export const OASIS_PARAMS = {
	chainId: "0x" + Number(CHAIN_IDs.oasis).toString(16),
	rpcUrls: ["https://sapphire.oasis.io"],
	chainName: "Oasis Sapphire",
	nativeCurrency: {
		name: "Oasis",
		symbol: "ROSE",
		decimals: 18,
	},
	blockExplorerUrls: ["https://explorer.sapphire.oasis.io"],
};
export const OASIS_TESTNET_PARAMS = {
	chainId: "0x" + Number(CHAIN_IDs.oasisTestnet).toString(16),
	rpcUrls: ["https://testnet.sapphire.oasis.dev"],
	chainName: "Oasis Sapphire Testnet",
	nativeCurrency: {
		name: "Oasis",
		symbol: "TEST",
		decimals: 18,
	},
	blockExplorerUrls: ["https://testnet.explorer.sapphire.oasis.dev"],
};
export const DOGE_PARAMS = {
	chainId: "0x" + Number(CHAIN_IDs.doge).toString(16),
	rpcUrls: ["https://rpc.dogechain.dog"],
	chainName: "Dogechain Mainnet",
	nativeCurrency: {
		name: "Doge",
		symbol: "DOGE",
		decimals: 18,
	},
	blockExplorerUrls: ["https://explorer.dogechain.dog"],
};
export const DOGE_TESTNET_PARAMS = {
	chainId: "0x" + Number(CHAIN_IDs.dogeTestnet).toString(16),
	rpcUrls: ["https://rpc-testnet.dogechain.dog"],
	chainName: "Dogechain Testnet",
	nativeCurrency: {
		name: "Doge",
		symbol: "wDOGE",
		decimals: 18,
	},
	blockExplorerUrls: ["https://explorer-testnet.dogechain.dog"],
};

export const OPBNB_PARAMS = {
	chainId: "0x" + Number(CHAIN_IDs.opBnb).toString(16),
	rpcUrls: ["https://opbnb-rpc.publicnode.com"],
	chainName: "opBNB",
	nativeCurrency: {
		symbol: "BNB",
		decimals: 18,
	},
	blockExplorerUrls: ["https://mainnet.opbnbscan.com"],
};
export const BNB_TESTNET_PARAMS = {
	chainId: "0x" + Number(CHAIN_IDs.bnbTestnet).toString(16),
	rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545/"],
	chainName: "opBNB - Testnet",
	nativeCurrency: {
		symbol: "tBNB",
		decimals: 18,
	},
	blockExplorerUrls: ["https://testnet.bscscan.com"],
};

export const BNB_MAINNET_PARAMS = {
	chainId: "0x" + Number(CHAIN_IDs.bnb).toString(16),
	rpcUrls: ["https://bsc-dataseed.binance.org/"],
	chainName: "BNB Chain",
	nativeCurrency: {
		symbol: "BNB",
		decimals: 18,
	},
	blockExplorerUrls: ["https://bscscan.com"],
};

export const SKALE_PARAMS = {
	chainId: "0x" + Number(CHAIN_IDs.skale).toString(16),
	chainName: "Calypso NFT Hub (SKALE)",
	rpcUrls: ["https://mainnet.skalenodes.com/v1/honorable-steel-rasalhague"],
	nativeCurrency: {
		name: "SKALE FUEL",
		symbol: "sFUEL",
		decimals: 18,
	},
	blockExplorerUrls: [
		"https://honorable-steel-rasalhague.explorer.mainnet.skalenodes.com",
	],
};
export const SKALE_TESTNET_PARAMS = {
	chainId: "0x" + Number(CHAIN_IDs.skaleTestnet).toString(16),
	chainName: "Calypso NFT Hub (SKALE Testnet)",
	rpcUrls: ["https://testnet.skalenodes.com/v1/giant-half-dual-testnet"],
	nativeCurrency: {
		name: "SKALE FUEL",
		symbol: "sFUEL",
		decimals: 18,
	},
	blockExplorerUrls: [
		"https://staging-fast-active-bellatrix.explorer.staging-v3.skalenodes.com",
	],
};
export const POLYGON_MAINNET_PARAMS = {
	chainId: "0x" + Number(CHAIN_IDs.polygon).toString(16),
	rpcUrls: ["https://rpc-mainnet.maticvigil.com"],
	// rpcUrls: ["https://polygon-mainnet.infura.io"],
	chainName: "Polygon Mainnet",
	nativeCurrency: {
		name: "Polygon",
		symbol: "MATIC",
		decimals: 18,
	},
	blockExplorerUrls: ["https://polygonscan.com"],
};
export const POLYGON_TESTNET_PARAMS = {
	chainId: "0x" + Number(CHAIN_IDs.polygonTestnet).toString(16),
	rpcUrls: ["https://rpc-mumbai.maticvigil.com"],
	chainName: "Polygon Testnet",
	nativeCurrency: {
		name: "Mumbai",
		symbol: "MATIC",
		decimals: 18,
	},
	blockExplorerUrls: ["https://mumbai.polygonscan.com/"],
};

export const SKALE_EUROPA_MAINNET_PARAMS = {
	chainId: "0x" + Number(CHAIN_IDs.skaleEuropa).toString(16),
	rpcUrls: ["https://mainnet.skalenodes.com/v1/elated-tan-skat"],
	chainName: "SKALE Europa Hub",
	nativeCurrency: {
		name: "sFUEL",
		symbol: "sFUEL",
		decimals: 18,
	},
	blockExplorerUrls: [
		"https://elated-tan-skat.explorer.mainnet.skalenodes.com",
	],
};

export const COREDAO_TESTNET_PARAMS = {
	chainId: "0x" + Number(CHAIN_IDs.coredaoTestnet).toString(16),
	rpcUrls: ["https://rpc.test.btcs.network"],
	chainName: "Core Blockchain Testnet",
	nativeCurrency: {
		name: "tCORE",
		symbol: "tCORE",
		decimals: 18,
	},
	blockExplorerUrls: ["https://scan.test.btcs.network"],
};

export const COREDAO_MAINNET_PARAMS = {
	chainId: "0x" + Number(CHAIN_IDs.coredao).toString(16),
	rpcUrls: ["https://rpc.coredao.org"],
	chainName: "Core Blockchain Mainnet",
	nativeCurrency: {
		name: "CORE",
		symbol: "CORE",
		decimals: 18,
	},
	blockExplorerUrls: ["https://scan.coredao.org"],
};

export const ARTHERA_MAINNET_PARAMS = {
	chainId: "0x" + Number(CHAIN_IDs.arthera).toString(16),
	rpcUrls: ["https://rpc.arthera.net"],
	chainName: "Arthera Mainnet",
	nativeCurrency: {
		name: "AA",
		symbol: "AA",
		decimals: 18,
	},
	blockExplorerUrls: ["https://explorer.arthera.net"],
};

export const ARTHERA_TESTNET_PARAMS = {
	chainId: "0x" + Number(CHAIN_IDs.artheraTestnet).toString(16),
	rpcUrls: ["https://rpc-test.arthera.net"],
	chainName: "Arthera Testnet",
	nativeCurrency: {
		name: "AA",
		symbol: "AA",
		decimals: 18,
	},
	blockExplorerUrls: ["https://explorer-test.arthera.net"],
};

export const PIXUDI_PARAMS = {
	chainId: "0x" + Number(CHAIN_IDs.pixudi).toString(16),
	rpcUrls: ["https://fraa-flashbox-2986-rpc.a.stagenet.tanssi.network"],
	chainName: "Pixudi Chain",
	nativeCurrency: {
		name: "PIXUDI",
		symbol: "PIXUDI",
		decimals: 18,
	},
	blockExplorerUrls: [
		"https://fraa-flashbox-2986-rpc.a.stagenet.tanssi.network",
	],
};

export const PIXUDI_TESTNET_PARAMS = {
	chainId: "0x" + Number(CHAIN_IDs.pixudiTestnet).toString(16),
	rpcUrls: ["https://fraa-flashbox-2986-rpc.a.stagenet.tanssi.network"],
	chainName: "Pixudi Chain",
	nativeCurrency: {
		name: "PIXUDI",
		symbol: "PIXUDI",
		decimals: 18,
	},
	blockExplorerUrls: [
		"https://fraa-flashbox-2986-rpc.a.stagenet.tanssi.network",
	],
};

export const NEOX_TESTNET_PARAMS = {
	chainId: "0x" + Number(CHAIN_IDs.neoX).toString(16),
	rpcUrls: ["https://neoxseed1.ngd.network"],
	chainName: "Neo X",
	nativeCurrency: {
		name: "GAS",
		symbol: "GAS",
		decimals: 18,
	},
	blockExplorerUrls: ["https://xt3scan.ngd.network/"],
};

export const CHAIN_SETTINGS_MAINNET_AND_TESTNET = {
	VELAS_MAINNET: {
		id: 106,
		params: VELAS_PARAMS,
	},
	VELAS_TESTNET: {
		id: 111,
		params: VELAS_TESTNET_PARAMS,
	},
	ETH_MAINNET: {
		id: 1,
		params: {},
	},
	ETH_TESTNET: {
		id: 5,
		params: {},
	},
	OPBNB_MAINNET: {
		id: 204,
		params: OPBNB_PARAMS,
	},
	BNB_TESTNET: {
		id: 97,
		params: BNB_TESTNET_PARAMS,
	},
	BNB_MAINNET: {
		id: 56,
		params: BNB_MAINNET_PARAMS,
	},
	BASE_MAINNET: {
		id: 8453,
		params: BASE_MAINNET_PARAMS,
	},
	AVAX_MAINNET: {
		id: 43114,
		params: AVALANCHE_MAINNET_PARAMS,
	},
	AVAX_TESTNET: {
		id: 43113,
		params: AVALANCHE_TESTNET_PARAMS,
	},
	GLMR_MAINNET: {
		id: 1284,
		params: MOONBEAM_PARAMS,
	},
	GLMR_TESTNET: {
		id: 1287,
		params: MOONBEAM_TESTNET_PARAMS,
	},
	OASIS_MAINNET: {
		id: 23294,
		params: OASIS_PARAMS,
	},
	OASIS_TESTNET: {
		id: 23295,
		params: OASIS_TESTNET_PARAMS,
	},
	DOGE_MAINNET: {
		id: 2000,
		params: DOGE_PARAMS,
	},
	DOGE_TESTNET: {
		id: 568,
		params: DOGE_TESTNET_PARAMS,
	},
	SKALE_MAINNET: {
		// id: 1351057110,
		id: 1564830818,
		params: SKALE_PARAMS,
	},
	SKALE_TESTNET: {
		//id: 344106930,
		id: 974399131,
		params: SKALE_TESTNET_PARAMS,
	},
	POLYGON_MAINNET: {
		id: 137,
		params: POLYGON_MAINNET_PARAMS,
	},
	POLYGON_TESTNET: {
		id: 80001,
		params: POLYGON_TESTNET_PARAMS,
	},
	SKALE_EUROPA_MAINNET: {
		id: 2046399126,
		params: SKALE_EUROPA_MAINNET_PARAMS,
	},
	COREDAO_TESTNET: {
		id: 1115,
		params: COREDAO_TESTNET_PARAMS,
	},
	COREDAO_MAINNET: {
		id: 1116,
		params: COREDAO_MAINNET_PARAMS,
	},
	ARTHERA_TESTNET: {
		id: 10243,
		params: ARTHERA_TESTNET_PARAMS,
	},
	ARTHERA_MAINNET: {
		id: 10242,
		params: ARTHERA_MAINNET_PARAMS,
	},
	PIXUDI_MAINNET: {
		id: 10899,
		params: PIXUDI_PARAMS,
	},
	PIXUDI_TESTNET: {
		id: 10899,
		params: PIXUDI_TESTNET_PARAMS,
	},
	NEOX_MAINNET: {
		id: 12227331,
		params: NEOX_TESTNET_PARAMS,
	},
	NEOX_TESTNET: {
		id: 12227331,
		params: NEOX_TESTNET_PARAMS,
	},
};
