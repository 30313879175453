import { CHAIN_SETTINGS_MAINNET_AND_TESTNET } from "src/utils/crypto/metamask/networkParams";
import { logger } from "src/utils/disableLogs";

let CONTAINER_SERVER_DOMAIN: any = "";

const LOCAL_SERVER_DOMAIN = "http://localhost:3000";
const MAIN_SERVER_DOMAIN = "https://api.neobox.world";
const DEV_SERVER_DOMAIN = "http://80.85.140.82:4005";

const RPC_VELAS_TESTNET = "https://evmexplorer.testnet.velas.com/rpc";
const RPC_OASIS_TESTNET = "https://testnet.sapphire.oasis.dev/";
const RPC_AVAX_TESTNET = "https://ava-testnet.public.blastapi.io/ext/bc/C/rpc";
const RPC_SKALE_TESTNET =
	"https://testnet.skalenodes.com/v1/giant-half-dual-testnet";
const RPC_COREDAO_TESTNET = "https://rpc.test.btcs.network";
const RPC_NEO_TESTNET = "https://testnet2.neo.coz.io:443";
const RPC_ARTHERA_TESTNET = "https://rpc-test.arthera.net";
const RPC_BNB_TESTNET = "https://data-seed-prebsc-1-s1.binance.org:8545/";

const RPC_NEO_MAINNET = "https://mainnet1.neo.coz.io:443";
const RPC_VELAS_MAINNET = "https://api.velas.com/rpc";
const RPC_OASIS_MAINNETT = "https://sapphire.oasis.io";
const RPC_AVAX_MAINNET = "https://api.avax.network/ext/bc/C/rpc";
export const RPC_SKALE_MAINNET =
	"https://mainnet.skalenodes.com/v1/honorable-steel-rasalhague";
const RPC_COREDAO_MAINNET = "https://rpc.coredao.org";
export const RPC_ARTHERA_MAINNET = "https://rpc.arthera.net";
export const RPC_PIXUDI_MAINNET =
	"https://fraa-flashbox-2986-rpc.a.stagenet.tanssi.network";
const RPC_OPBNB_MAINNET = "https://opbnb-rpc.publicnode.com";

if (process.env.REACT_APP_MODE === "local") {
	CONTAINER_SERVER_DOMAIN = LOCAL_SERVER_DOMAIN;

	logger.enableLogger();
}
if (process.env.REACT_APP_MODE === "dev") {
	CONTAINER_SERVER_DOMAIN = DEV_SERVER_DOMAIN;

	logger.enableLogger();
}
if (process.env.REACT_APP_MODE === "prod") {
	CONTAINER_SERVER_DOMAIN = MAIN_SERVER_DOMAIN;

	logger.disableLogger();
}

export const SERVER_DOMAIN = CONTAINER_SERVER_DOMAIN;

let CONTAINER_ETH_NETWORK_ID = 0;
let CONTAINER_BNB_NETWORK_ID = 0;
let CONTAINER_OASIS_NETWORK_ID = 0;
let CONTAINER_SKALE_NETWORK_ID = 0;
let CONTAINER_VELAS_NETWORK_ID = 0;
let CONTAINER_AVAX_NETWORK_ID = 0;
let CONTAINER_SKALE_EUROPA_NETWORK_ID = 0;
let CONTAINER_CORE_NETWORK_ID = 0;
let CONTAINER_ARTHERA_NETWORK_ID = 0;
let CONTAINER_PIXUDI_NETWORK_ID = 0;
let CONTAINER_BASE_NETWORK_ID = 0;

if (process.env.REACT_APP_MODE_FOR_PAYMENT === "local") {
	CONTAINER_ETH_NETWORK_ID = CHAIN_SETTINGS_MAINNET_AND_TESTNET.ETH_TESTNET.id;
	CONTAINER_BNB_NETWORK_ID = CHAIN_SETTINGS_MAINNET_AND_TESTNET.BNB_TESTNET.id;
	CONTAINER_OASIS_NETWORK_ID =
		CHAIN_SETTINGS_MAINNET_AND_TESTNET.OASIS_TESTNET.id;
	CONTAINER_SKALE_NETWORK_ID =
		CHAIN_SETTINGS_MAINNET_AND_TESTNET.SKALE_TESTNET.id;
	CONTAINER_VELAS_NETWORK_ID =
		CHAIN_SETTINGS_MAINNET_AND_TESTNET.VELAS_TESTNET.id;
	CONTAINER_AVAX_NETWORK_ID =
		CHAIN_SETTINGS_MAINNET_AND_TESTNET.AVAX_TESTNET.id;
	CONTAINER_SKALE_EUROPA_NETWORK_ID =
		CHAIN_SETTINGS_MAINNET_AND_TESTNET.SKALE_TESTNET.id;
	CONTAINER_CORE_NETWORK_ID =
		CHAIN_SETTINGS_MAINNET_AND_TESTNET.COREDAO_TESTNET.id;
	CONTAINER_ARTHERA_NETWORK_ID =
		CHAIN_SETTINGS_MAINNET_AND_TESTNET.ARTHERA_TESTNET.id;
	CONTAINER_PIXUDI_NETWORK_ID =
		CHAIN_SETTINGS_MAINNET_AND_TESTNET.PIXUDI_TESTNET.id;
	CONTAINER_BASE_NETWORK_ID =
		CHAIN_SETTINGS_MAINNET_AND_TESTNET.BASE_MAINNET.id;
}

if (process.env.REACT_APP_MODE_FOR_PAYMENT === "dev") {
	CONTAINER_ETH_NETWORK_ID = CHAIN_SETTINGS_MAINNET_AND_TESTNET.ETH_TESTNET.id;
	CONTAINER_BNB_NETWORK_ID = CHAIN_SETTINGS_MAINNET_AND_TESTNET.BNB_TESTNET.id;
	CONTAINER_OASIS_NETWORK_ID =
		CHAIN_SETTINGS_MAINNET_AND_TESTNET.OASIS_TESTNET.id;
	CONTAINER_SKALE_NETWORK_ID =
		CHAIN_SETTINGS_MAINNET_AND_TESTNET.SKALE_MAINNET.id;
	CONTAINER_VELAS_NETWORK_ID =
		CHAIN_SETTINGS_MAINNET_AND_TESTNET.VELAS_TESTNET.id;
	CONTAINER_AVAX_NETWORK_ID =
		CHAIN_SETTINGS_MAINNET_AND_TESTNET.AVAX_TESTNET.id;
	CONTAINER_SKALE_EUROPA_NETWORK_ID =
		CHAIN_SETTINGS_MAINNET_AND_TESTNET.SKALE_TESTNET.id;
	CONTAINER_CORE_NETWORK_ID =
		CHAIN_SETTINGS_MAINNET_AND_TESTNET.COREDAO_TESTNET.id;
	CONTAINER_ARTHERA_NETWORK_ID =
		CHAIN_SETTINGS_MAINNET_AND_TESTNET.ARTHERA_MAINNET.id;
	CONTAINER_PIXUDI_NETWORK_ID =
		CHAIN_SETTINGS_MAINNET_AND_TESTNET.PIXUDI_TESTNET.id;
	CONTAINER_BASE_NETWORK_ID =
		CHAIN_SETTINGS_MAINNET_AND_TESTNET.BASE_MAINNET.id;
}

if (process.env.REACT_APP_MODE_FOR_PAYMENT === "prod") {
	CONTAINER_ETH_NETWORK_ID = CHAIN_SETTINGS_MAINNET_AND_TESTNET.ETH_MAINNET.id;
	CONTAINER_BNB_NETWORK_ID = CHAIN_SETTINGS_MAINNET_AND_TESTNET.BNB_MAINNET.id;
	CONTAINER_OASIS_NETWORK_ID =
		CHAIN_SETTINGS_MAINNET_AND_TESTNET.OASIS_MAINNET.id;
	CONTAINER_SKALE_NETWORK_ID =
		CHAIN_SETTINGS_MAINNET_AND_TESTNET.SKALE_MAINNET.id;
	CONTAINER_VELAS_NETWORK_ID =
		CHAIN_SETTINGS_MAINNET_AND_TESTNET.VELAS_MAINNET.id;
	CONTAINER_AVAX_NETWORK_ID =
		CHAIN_SETTINGS_MAINNET_AND_TESTNET.AVAX_MAINNET.id;
	CONTAINER_SKALE_EUROPA_NETWORK_ID =
		CHAIN_SETTINGS_MAINNET_AND_TESTNET.SKALE_EUROPA_MAINNET.id;
	CONTAINER_CORE_NETWORK_ID =
		CHAIN_SETTINGS_MAINNET_AND_TESTNET.COREDAO_MAINNET.id;
	CONTAINER_ARTHERA_NETWORK_ID =
		CHAIN_SETTINGS_MAINNET_AND_TESTNET.ARTHERA_MAINNET.id;
	CONTAINER_PIXUDI_NETWORK_ID =
		CHAIN_SETTINGS_MAINNET_AND_TESTNET.PIXUDI_MAINNET.id;
	CONTAINER_BASE_NETWORK_ID =
		CHAIN_SETTINGS_MAINNET_AND_TESTNET.BASE_MAINNET.id;
}

export const METAMASK_NETWORK_IDS = {
	ETH: CONTAINER_ETH_NETWORK_ID,
	BNB: CONTAINER_BNB_NETWORK_ID,
	OASIS: CONTAINER_OASIS_NETWORK_ID,
	SKALE: CONTAINER_SKALE_NETWORK_ID,
	VELAS: CONTAINER_VELAS_NETWORK_ID,
	AVAX: CONTAINER_AVAX_NETWORK_ID,
	SKALE_EUROPA: CONTAINER_SKALE_EUROPA_NETWORK_ID,
	CORE: CONTAINER_CORE_NETWORK_ID,
	ARTHERA: CONTAINER_ARTHERA_NETWORK_ID,
	PIXUDI: CONTAINER_PIXUDI_NETWORK_ID,
	BASE: CONTAINER_BASE_NETWORK_ID,
};

// SETTINGS CHAIN FOR METAMASK AUTHORIZATION AND REGISTRATION
const CHAIN_SETTINGS_METAMASK_PROD = {
	VELAS: CHAIN_SETTINGS_MAINNET_AND_TESTNET.VELAS_MAINNET,
	ETH: CHAIN_SETTINGS_MAINNET_AND_TESTNET.ETH_MAINNET,
	BNB: CHAIN_SETTINGS_MAINNET_AND_TESTNET.BNB_MAINNET,
	AVAX: CHAIN_SETTINGS_MAINNET_AND_TESTNET.AVAX_MAINNET,
	GLMR: CHAIN_SETTINGS_MAINNET_AND_TESTNET.GLMR_MAINNET,
	OASIS: CHAIN_SETTINGS_MAINNET_AND_TESTNET.OASIS_MAINNET,
	DOGE: CHAIN_SETTINGS_MAINNET_AND_TESTNET.DOGE_MAINNET,
	SKALE: CHAIN_SETTINGS_MAINNET_AND_TESTNET.SKALE_MAINNET,
	POLYGON: CHAIN_SETTINGS_MAINNET_AND_TESTNET.POLYGON_MAINNET,
	COREDAO: CHAIN_SETTINGS_MAINNET_AND_TESTNET.COREDAO_MAINNET,
	ARTHERA: CHAIN_SETTINGS_MAINNET_AND_TESTNET.ARTHERA_MAINNET,
	PIXUDI: CHAIN_SETTINGS_MAINNET_AND_TESTNET.PIXUDI_MAINNET,
	BASE: CHAIN_SETTINGS_MAINNET_AND_TESTNET.BASE_MAINNET,
	NEOX: CHAIN_SETTINGS_MAINNET_AND_TESTNET.NEOX_MAINNET,
};
const CHAIN_SETTINGS_METAMASK_LOCAL = {
	VELAS: CHAIN_SETTINGS_MAINNET_AND_TESTNET.VELAS_MAINNET,
	ETH: CHAIN_SETTINGS_MAINNET_AND_TESTNET.ETH_MAINNET,
	BNB: CHAIN_SETTINGS_MAINNET_AND_TESTNET.BNB_MAINNET,
	AVAX: CHAIN_SETTINGS_MAINNET_AND_TESTNET.AVAX_MAINNET,
	GLMR: CHAIN_SETTINGS_MAINNET_AND_TESTNET.GLMR_MAINNET,
	OASIS: CHAIN_SETTINGS_MAINNET_AND_TESTNET.OASIS_MAINNET,
	DOGE: CHAIN_SETTINGS_MAINNET_AND_TESTNET.DOGE_MAINNET,
	SKALE: CHAIN_SETTINGS_MAINNET_AND_TESTNET.SKALE_MAINNET,
	POLYGON: CHAIN_SETTINGS_MAINNET_AND_TESTNET.POLYGON_MAINNET,
	COREDAO: CHAIN_SETTINGS_MAINNET_AND_TESTNET.COREDAO_TESTNET,
	ARTHERA: CHAIN_SETTINGS_MAINNET_AND_TESTNET.ARTHERA_TESTNET,
	PIXUDI: CHAIN_SETTINGS_MAINNET_AND_TESTNET.PIXUDI_TESTNET,
	BASE: CHAIN_SETTINGS_MAINNET_AND_TESTNET.BASE_MAINNET,
	NEOX: CHAIN_SETTINGS_MAINNET_AND_TESTNET.NEOX_TESTNET,
};
const CHAIN_SETTINGS_METAMASK_DEV = {
	VELAS: CHAIN_SETTINGS_MAINNET_AND_TESTNET.VELAS_TESTNET,
	ETH: CHAIN_SETTINGS_MAINNET_AND_TESTNET.ETH_TESTNET,
	BNB: CHAIN_SETTINGS_MAINNET_AND_TESTNET.BNB_MAINNET,
	AVAX: CHAIN_SETTINGS_MAINNET_AND_TESTNET.AVAX_MAINNET,
	GLMR: CHAIN_SETTINGS_MAINNET_AND_TESTNET.GLMR_TESTNET,
	OASIS: CHAIN_SETTINGS_MAINNET_AND_TESTNET.OASIS_TESTNET,
	DOGE: CHAIN_SETTINGS_MAINNET_AND_TESTNET.DOGE_TESTNET,
	SKALE: CHAIN_SETTINGS_MAINNET_AND_TESTNET.SKALE_TESTNET,
	POLYGON: CHAIN_SETTINGS_MAINNET_AND_TESTNET.POLYGON_TESTNET,
	COREDAO: CHAIN_SETTINGS_MAINNET_AND_TESTNET.COREDAO_TESTNET,
	ARTHERA: CHAIN_SETTINGS_MAINNET_AND_TESTNET.ARTHERA_MAINNET,
	PIXUDI: CHAIN_SETTINGS_MAINNET_AND_TESTNET.PIXUDI_TESTNET,
	BASE: CHAIN_SETTINGS_MAINNET_AND_TESTNET.BASE_MAINNET,
	NEOX: CHAIN_SETTINGS_MAINNET_AND_TESTNET.NEOX_TESTNET,
};

const CHAIN_SETTINGS_METAMASK_BETA = {
	VELAS: CHAIN_SETTINGS_MAINNET_AND_TESTNET.VELAS_MAINNET,
	ETH: CHAIN_SETTINGS_MAINNET_AND_TESTNET.ETH_MAINNET,
	BNB: CHAIN_SETTINGS_MAINNET_AND_TESTNET.OPBNB_MAINNET,
	AVAX: CHAIN_SETTINGS_MAINNET_AND_TESTNET.AVAX_MAINNET,
	GLMR: CHAIN_SETTINGS_MAINNET_AND_TESTNET.GLMR_MAINNET,
	OASIS: CHAIN_SETTINGS_MAINNET_AND_TESTNET.OASIS_MAINNET,
	DOGE: CHAIN_SETTINGS_MAINNET_AND_TESTNET.DOGE_MAINNET,
	SKALE: CHAIN_SETTINGS_MAINNET_AND_TESTNET.SKALE_MAINNET,
	POLYGON: CHAIN_SETTINGS_MAINNET_AND_TESTNET.POLYGON_MAINNET,
	COREDAO: CHAIN_SETTINGS_MAINNET_AND_TESTNET.COREDAO_MAINNET,
	ARTHERA: CHAIN_SETTINGS_MAINNET_AND_TESTNET.ARTHERA_MAINNET,
	PIXUDI: CHAIN_SETTINGS_MAINNET_AND_TESTNET.PIXUDI_MAINNET,
	BASE: CHAIN_SETTINGS_MAINNET_AND_TESTNET.BASE_MAINNET,
	NEOX: CHAIN_SETTINGS_MAINNET_AND_TESTNET.NEOX_MAINNET,
};

export const CHAIN_SETTINGS_METAMASK_AUTH =
	process.env.REACT_APP_MODE === "prod"
		? CHAIN_SETTINGS_METAMASK_PROD
		: process.env.REACT_APP_MODE === "dev"
		? CHAIN_SETTINGS_METAMASK_DEV
		: process.env.REACT_APP_MODE === "beta"
		? CHAIN_SETTINGS_METAMASK_BETA
		: CHAIN_SETTINGS_METAMASK_LOCAL;
