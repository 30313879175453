import { CHAIN_SETTINGS_METAMASK_AUTH } from "src/config/config";
import { IAuth } from "src/types/user";

export const getChainIdFromAuthType = (type: IAuth.AuthTypes) => {
	switch (type) {
		case IAuth.AuthTypes.avax: {
			return CHAIN_SETTINGS_METAMASK_AUTH.AVAX.id;
		}
		case IAuth.AuthTypes.velas: {
			return CHAIN_SETTINGS_METAMASK_AUTH.VELAS.id;
		}
		case IAuth.AuthTypes.oasis: {
			return CHAIN_SETTINGS_METAMASK_AUTH.OASIS.id;
		}
		case IAuth.AuthTypes.skale: {
			return CHAIN_SETTINGS_METAMASK_AUTH.SKALE.id;
		}
		case IAuth.AuthTypes.coredao: {
			return CHAIN_SETTINGS_METAMASK_AUTH.COREDAO.id;
		}
		case IAuth.AuthTypes.arthera: {
			return CHAIN_SETTINGS_METAMASK_AUTH.ARTHERA.id;
		}
		case IAuth.AuthTypes.bnb: {
			return CHAIN_SETTINGS_METAMASK_AUTH.BNB.id;
		}
		case IAuth.AuthTypes.pixudi: {
			return CHAIN_SETTINGS_METAMASK_AUTH.PIXUDI.id;
		}
		case IAuth.AuthTypes.base: {
			return CHAIN_SETTINGS_METAMASK_AUTH.BASE.id;
		}
		case IAuth.AuthTypes.neox: {
			return CHAIN_SETTINGS_METAMASK_AUTH.NEOX.id;
		}
	}
	return 0;
};
