import styles from "./Header.module.scss";
import neobox from "../../assets/img/svg/neobox.svg";
import { useWallet as useWalletNeo } from "@rentfuse-labs/neo-wallet-adapter-react";
import { getNeoLineWallet } from "@rentfuse-labs/neo-wallet-adapter-wallets";
import { fetchGetNonce } from "src/app/api/app";
import { useContext, useState } from "react";
import { Context } from "src";
import { observer } from "mobx-react-lite";
import { NavLink, useNavigate } from "react-router-dom";
import { IAuth, ISignedData } from "src/types/user";
import { useIntl } from "react-intl";
import cl from "classnames";
import { toast } from "react-toastify";
import { InstallWallet } from "../InstallWallet/InstallWallet";
import { modal } from "../Modal/ModalConnector";
import menu from "../../assets/img/svg/menu.svg";
import closeImage from "../../assets/img/svg/close.svg";

export const Header = observer(() => {
	const { storeUser } = useContext(Context);
	const navigate = useNavigate();
	const intl = useIntl();
	const [isOpenSelectLanguge, setIsOpenSelectLanguage] = useState(false);
	const languages = ["en", "中文", "日本語"];
	const [currentLanguage, setCurrentLanguage] = useState<string>(
		storeUser.language === "ja"
			? "日本語"
			: storeUser.language === "ch"
			? "中文"
			: "en"
	);
	const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);

	//NEOLine
	const neoLine = useWalletNeo();

	const authNeoLine = async () => {
		console.log(neoLine);
		if (window.NEOLineN3 === undefined) {
			//alert("NEOLine Wallet is not installed");
			return;
		} else {
			try {
				const neoline = new window.NEOLineN3.Init();
				neoLine.wallet = getNeoLineWallet();
				const adapter = neoLine.wallet.adapter();
				neoLine.adapter = adapter;
				await neoLine.adapter.connect();
				console.log(adapter, neoLine);
				let account = await neoline.getAccount();
				console.log("account", account);
				let randomSalt = String(Math.random() * 99999999);
				console.log("randomSalt", randomSalt);
				const publicKey = await neoline.getPublicKey();
				console.log("publicKey", publicKey);
				const resNonce = await fetchGetNonce(
					IAuth.AuthTypes.neo,
					publicKey.publicKey
				);
				console.log("resNonce", resNonce);
				const signedData: ISignedData = await neoline.signMessage({
					message: String(resNonce.data.nonce),
				});
				console.log("signedData", signedData);

				if (account.address !== "") {
					const res = await storeUser.login(signedData, account.address);
					if (res) {
						navigate("/panel");
					}
				} else {
					alert("Log in to the extension!");
				}
			} catch (er) {
				console.error("Something went wrong", er);
			}
		}
		//}
	};

	const arrow = (
		<svg
			width="12"
			height="6"
			viewBox="0 0 12 6"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M0.930992 0.157003L5.61599 4.768C5.71821 4.86913 5.8562 4.92585 5.99999 4.92585C6.14378 4.92585 6.28177 4.86913 6.38399 4.768L11.069 0.158004C11.1718 0.0569282 11.3103 0.000291824 11.4545 0.000291824C11.5987 0.000291824 11.7371 0.0569282 11.84 0.158004C11.8906 0.207381 11.9309 0.266397 11.9584 0.331573C11.9859 0.39675 12 0.46677 12 0.537504C12 0.608239 11.9859 0.678257 11.9584 0.743434C11.9309 0.808611 11.8906 0.867627 11.84 0.917004L7.15599 5.527C6.84747 5.82993 6.43237 5.99965 5.99999 5.99965C5.56761 5.99965 5.15251 5.82993 4.84399 5.527L0.159992 0.917004C0.10919 0.867611 0.0688076 0.808534 0.0412328 0.743264C0.0136581 0.677995 -0.000549316 0.607859 -0.000549316 0.537004C-0.000549316 0.466148 0.0136581 0.396013 0.0412328 0.330744C0.0688076 0.265474 0.10919 0.206396 0.159992 0.157003C0.262847 0.0559278 0.401285 -0.00070858 0.545492 -0.00070858C0.689698 -0.00070858 0.828136 0.0559278 0.930992 0.157003Z"
				fill="#002430"
			/>
		</svg>
	);

	return (
		<div className={styles.wrapperHeader}>
			<div
				className={styles.content}
				style={{ display: isOpenMenu ? "none" : "flex" }}
			>
				<div className={styles.logo} onClick={() => navigate("/")}>
					<img src={neobox} alt="" />
					<div>neobox</div>
				</div>
				<div className={styles.buttons}>
					<div
						onClick={() =>
							storeUser.isAuth ? navigate("/apps") : navigate("/welcome")
						}
						className={styles.app}
					>
						<div>{intl.formatMessage({ id: "Apps" })}</div>
						<div>{intl.formatMessage({ id: "NEW" })}</div>
					</div>
					<div>
						{/* <NavLink to="/#neobox" className={styles.features}> */}
						{intl.formatMessage({ id: "Features" })}
						{/* </NavLink> */}
					</div>
					<div
						style={{ cursor: "pointer" }}
						onClick={() =>
							storeUser.isAuth ? navigate("/panel") : navigate("/welcome")
						}
					>
						{intl.formatMessage({ id: "My Storage" })}
					</div>
					<div className={styles.select}>
						<div
							className={styles.selectBc}
							onClick={() => {
								setIsOpenSelectLanguage(!isOpenSelectLanguge);
							}}
						>
							<div className={styles.selectBcValue} style={{ width: "124px" }}>
								{currentLanguage}
							</div>
							<div
								className={cl(styles.selectBcArrow, {
									[styles.active]: isOpenSelectLanguge,
								})}
							>
								{arrow}
							</div>
						</div>
						{isOpenSelectLanguge && (
							<div className={styles.optionsSelectBc}>
								{languages.map((option) => {
									return (
										<div
											className={styles.optionSelectBc}
											onClick={() => {
												setCurrentLanguage(option);
												setIsOpenSelectLanguage(!isOpenSelectLanguge);
												console.log("option", option);
												if (option === "en") {
													storeUser.changeLanguage("en");
												} else if (option === "中文") {
													storeUser.changeLanguage("ch");
												} else if (option === "日本語") {
													storeUser.changeLanguage("ja");
												}
											}}
										>
											<div>{option}</div>
										</div>
									);
								})}
							</div>
						)}
					</div>
					{storeUser.isAuth ? (
						<div onClick={() => storeUser.logout()} className={styles.connect}>
							{intl.formatMessage({ id: "Log out" })}
						</div>
					) : (
						<div
							onClick={() => navigate("/welcome")}
							className={styles.connect}
						>
							{intl.formatMessage({ id: "Log in" })}
						</div>
					)}
				</div>
				<img
					src={menu}
					alt=""
					className={styles.menu}
					onClick={() => {
						setIsOpenMenu(!isOpenMenu);
					}}
				/>
			</div>
			{isOpenMenu && (
				<div className={cl(styles.buttons, styles.buttonsMobile)} id="mobile">
					<div className={styles.logo} onClick={() => navigate("/")}>
						<img src={neobox} alt="" />
						<div>neobox</div>
					</div>
					<div
						onClick={() =>
							storeUser.isAuth ? navigate("/apps") : navigate("/welcome")
						}
						className={styles.app}
					>
						<div>{intl.formatMessage({ id: "Apps" })}</div>
						<div>{intl.formatMessage({ id: "NEW" })}</div>
					</div>
					<div>
						{/* <NavLink to="/#neobox" className={styles.features}> */}
						{intl.formatMessage({ id: "Features" })}
						{/* </NavLink> */}
					</div>
					<div
						style={{ cursor: "pointer" }}
						onClick={() =>
							storeUser.isAuth ? navigate("/panel") : navigate("/welcome")
						}
					>
						{intl.formatMessage({ id: "My Storage" })}
					</div>
					<div className={styles.select}>
						<div
							className={styles.selectBc}
							onClick={() => {
								setIsOpenSelectLanguage(!isOpenSelectLanguge);
							}}
						>
							<div className={styles.selectBcValue} style={{ width: "124px" }}>
								{currentLanguage}
							</div>
							<div
								className={cl(styles.selectBcArrow, {
									[styles.active]: isOpenSelectLanguge,
								})}
							>
								{arrow}
							</div>
						</div>
						{isOpenSelectLanguge && (
							<div className={styles.optionsSelectBc}>
								{languages.map((option) => {
									return (
										<div
											className={styles.optionSelectBc}
											onClick={() => {
												setCurrentLanguage(option);
												setIsOpenSelectLanguage(!isOpenSelectLanguge);
												console.log("option", option);
												if (option === "en") {
													storeUser.changeLanguage("en");
												} else if (option === "中文") {
													storeUser.changeLanguage("ch");
												} else if (option === "日本語") {
													storeUser.changeLanguage("ja");
												}
											}}
										>
											<div>{option}</div>
										</div>
									);
								})}
							</div>
						)}
					</div>
					{storeUser.isAuth ? (
						<div onClick={() => storeUser.logout()} className={styles.connect}>
							{intl.formatMessage({ id: "Log out" })}
						</div>
					) : (
						<div
							onClick={() => navigate("/welcome")}
							className={styles.connect}
						>
							{intl.formatMessage({ id: "Log in" })}
						</div>
					)}
					<img
						src={closeImage}
						alt=""
						className={styles.close}
						onClick={() => setIsOpenMenu(false)}
					/>
				</div>
			)}
		</div>
	);
});
