export const logger = (function () {
	let oldConsoleLog = null;
	let oldConsoleError = null;
	let pub = {
		disableLogger: () => {},
		enableLogger: () => {},
	};

	pub.enableLogger = function enableLogger() {
		if (oldConsoleLog == null) return;

		window["console"]["log"] = oldConsoleLog;
		// @ts-ignore
		window["console"]["error"] = oldConsoleError;
	};

	pub.disableLogger = function disableLogger() {
		// @ts-ignore
		oldConsoleLog = console.log;
		// @ts-ignore
		oldConsoleError = console.error;
		window["console"]["log"] = function () {};
		window["console"]["error"] = function () {};
	};

	return pub;
})();
