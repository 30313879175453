import { useIntl } from "react-intl";
import styles from "./InstallWallet.module.scss";
import neoline from "../../assets/img/svg/neoline.svg";
import { modal } from "../Modal/ModalConnector";

export const InstallWallet = () => {
	const intl = useIntl();

	return (
		<div className={styles.content}>
			<div className={styles.title}>
				{intl.formatMessage({ id: "You need a Neoline wallet to log in" })}
			</div>
			<img
				src={neoline}
				alt=""
				className={styles.image}
				onClick={() => window.open("https://neoline.io")}
			/>
			<div className={styles.text}>
				{intl.formatMessage({ id: "Click to get it" })}
			</div>
			<div className={styles.textOpacity}>
				{intl.formatMessage({
					id: "Please",
				})}{" "}
				<span onClick={() => window.open("https://t.me/ducktalesblock")}>
					{intl.formatMessage({
						id: "contact us",
					})}{" "}
				</span>
				{intl.formatMessage({
					id: "if you have any questions or suggestions",
				})}
			</div>
			<div className={styles.btn} onClick={() => modal.close()}>
				{intl.formatMessage({ id: "Ok" })}
			</div>
		</div>
	);
};
