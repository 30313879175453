import axios from "axios";
import { SERVER_DOMAIN } from "../../config/config";
// import { SERVER_DOMAIN } from 'config/urls';
/* eslint no-underscore-dangle: 0 */
const agent = axios.create({
	// withCredentials: true,
	baseURL: SERVER_DOMAIN,
});

agent.interceptors.request.use((config: any) => {
	const result = { ...config };
	if (result.headers) {
		result.headers.Authorization = `Bearer ${localStorage.getItem(
			"accessToken"
		)}`;
	}
	return result;
});

agent.interceptors.response.use(
	(config: any) => {
		return config;
	},
	async (error: any) => {
		const originRequest = error.config;
		if (
			error.response.status === 401 &&
			error.config &&
			!error.config._isRetry
		) {
			originRequest._isRetry = true;
			try {
				const refreshToken = localStorage.getItem("refreshToken");
				const serverResponse = await agent.post(`/auth/refresh`, {
					refreshToken: refreshToken,
				});

				localStorage.setItem("accessToken", serverResponse.data.accessToken);
				localStorage.setItem("refreshToken", serverResponse.data.refreshToken);

				return agent.request(originRequest);
			} catch (repeatedError) {
				localStorage.removeItem("accessToken");
				localStorage.removeItem("refreshToken");
				localStorage.removeItem("typeAuthorization");
				localStorage.removeItem("wallet");
			}
		}
		// When error code !== 401
		throw error;
	}
);

export default agent;
