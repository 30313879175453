import { AxiosError } from "axios";
import { ethers } from "ethers";
import { AVALANCHE_MAINNET_PARAMS, CHAIN_IDs, CHAIN_SETTINGS_MAINNET_AND_TESTNET, SKALE_PARAMS, VELAS_PARAMS } from "./networkParams";

export interface IEthereumAccount {
	walletAddress: string;
	signature: string;
}

export interface IEthereumAccountInfo {
	provider: ethers.providers.Web3Provider;
	signer: ethers.providers.JsonRpcSigner;
	walletAddress: string;
	chainId?: number;
}

export const getEthereumAccountInfo = async (
	CHAIN_ID: number
): Promise<IEthereumAccountInfo | void> => {
	try {
		const ethereum = window.ethereum;
		if (!ethereum) {
			return alert("Install a MetaMask Wallet");
		}
		const chainIdMetaMask = parseInt(window.ethereum.networkVersion, 16);
		const chainIdNeed = Number(CHAIN_ID).toString(16);
		console.log(
			"chainId",
			chainIdMetaMask,
			chainIdNeed,
			window.ethereum.networkVersion
		);

		if (Number(chainIdMetaMask) !== Number(chainIdNeed)) {
			// alert('It is necessary to change the blockchain network')
			try {
				await window.ethereum.request({
					method: "wallet_switchEthereumChain",
					params: [
						{
							chainId: "0x" + chainIdNeed,
						},
					],
				});
			} catch (error: any) {
				let paramsChain;
				if (error.code === 4902) {
					switch (Number(CHAIN_ID)) {
						case CHAIN_IDs.avax:
							paramsChain = AVALANCHE_MAINNET_PARAMS;
							break;
						case CHAIN_IDs.velas:
							paramsChain = VELAS_PARAMS;
							break;
						case CHAIN_IDs.skale:
							paramsChain = SKALE_PARAMS;
							break;
					}
					try {
						await window.ethereum.request({
							method: "wallet_addEthereumChain",
							params: [paramsChain],
						});
					} catch (addError) {
						console.log(addError);
						return;
					}
				}
				console.log(error);
				// return
			}
		}
		return getInfoMetaMask(ethereum);
	} catch (e: AxiosError | unknown) {
		alert((e as AxiosError).message);
	}
};

export const getEthereumAccountInfoNew =
	async (): Promise<IEthereumAccountInfo | void> => {
		try {
			const ethereum = window.ethereum;
			if (!ethereum) {
				return alert("Install a MetaMask Wallet");
			}
			let chainId = Number(window.ethereum.networkVersion);
			console.log("chainId", chainId);

			if (String(window.ethereum.networkVersion).indexOf("x") > -1) {
				chainId = parseInt(window.ethereum.networkVersion, 16);
			}

			if (!Object.values(CHAIN_IDs).includes(chainId)) {
				alert("It is necessary to change the blockchain network");
				return;
			}
			return await getInfoMetaMask(ethereum, chainId);
		} catch (e: AxiosError | unknown) {
			alert((e as AxiosError).message);
		}
	};

export const getInfoMetaMask = async (
	ethereum: Window["ethereum"],
	chainId?: number
): Promise<IEthereumAccountInfo | void> => {
	try {
		await window.ethereum.request({ method: "eth_requestAccounts" });

		const provider: ethers.providers.Web3Provider =
			new ethers.providers.Web3Provider(
				ethereum as ethers.providers.ExternalProvider
			);
		const signer: ethers.providers.JsonRpcSigner = provider.getSigner();
		const walletAddress: string = await signer.getAddress();
		return { provider, signer, walletAddress, chainId };
	} catch (e: AxiosError | unknown) {
		alert((e as AxiosError).message);
	}
};

// -----------------------------------------------------------------------------------------------------------------
// AFTER VELAS CONNECT

export const getEthereumAccountVelas =
	async (): Promise<IEthereumAccountInfo | void> => {
		try {
			const ethereum = window.ethereum;
			if (!ethereum) {
				return alert("Install a MetaMask Wallet");
			}
			let chainId = Number(window.ethereum.networkVersion);
			console.log("chainId", chainId);

			if (Number(window.ethereum.networkVersion) !== CHAIN_IDs.velas) {
				// alert('It is necessary to change the blockchain network')
				try {
					await window.ethereum.request({
						method: "wallet_switchEthereumChain",
						params: [
							{
								chainId: "0x" + CHAIN_IDs.velas,
							},
						],
					});
				} catch (error: any) {
					if (error.code === 4902) {
						try {
							await window.ethereum.request({
								method: "wallet_addEthereumChain",
								params: [VELAS_PARAMS],
							});
						} catch (addError) {
							console.log(addError);
							return;
						}
					}
					console.log(error);
					// return
				}
			}

			return await getInfoMetaMask(ethereum, chainId);
		} catch (e: AxiosError | unknown) {
			alert((e as AxiosError).message);
			console.log(e);
		}
	};

export const getEthereumAccount = async (
	CHAIN_ID: number
): Promise<IEthereumAccountInfo | void> => {
	try {
		const ethereum = window.ethereum;
		if (!ethereum) {
			return alert("Install a MetaMask Wallet");
		}
		let metaMaskChainId = Number(window.ethereum.networkVersion);
		console.log("metaMaskChainId", metaMaskChainId, "CHAIN_ID", CHAIN_ID);

		if (metaMaskChainId !== CHAIN_ID) {
			alert("It is necessary to change the blockchain network");
			try {
				await window.ethereum.request({
					method: "wallet_switchEthereumChain",
					params: [
						{
							chainId: "0x" + CHAIN_ID,
						},
					],
				});
			} catch (error: any) {
				if (error.code === 4902) {
					try {
						await window.ethereum.request({
							method: "wallet_addEthereumChain",
							params: [
								Object.values(CHAIN_SETTINGS_MAINNET_AND_TESTNET).find(
									(chain) => chain.id === CHAIN_ID
								)?.params,
							],
						});
						// const chainId = await window.ethereum.request({ method: 'eth_chainId' });
						// if (parseInt(chainId, 16) !== CHAIN_ID) {
						//     return;
						// }
					} catch (addError) {
						console.log(addError);
						return;
					}
				}
				console.log(error);
				// return
			}
			const chainId = await window.ethereum.request({
				method: "eth_chainId",
			});
			if (parseInt(chainId, 16) !== CHAIN_ID) {
				return;
			}
		}

		return await getInfoMetaMask(ethereum, CHAIN_ID);
	} catch (e: AxiosError | unknown) {
		alert((e as AxiosError).message);
	}
};
