import { useContext, useEffect, useRef, useState } from "react";
import styles from "./FileItem.module.scss";
import { Context } from "src";
import { useIntl } from "react-intl";
import { DeleteFile } from "src/components/DeleteFile/DeleteFile";
import openIcon from "../../assets/img/svg/openIcon.svg";
import shareIcon from "../../assets/img/svg/shareIcon.svg";
import deleteIcon from "../../assets/img/svg/deleteIcon.svg";
import { ShareFile } from "src/components/ShareFile/ShareFile";
import filesImage from "../../assets/img/svg/files.svg";
import { IFile } from "src/types/user";
import points from "../../assets/img/svg/points.svg";
import { observer } from "mobx-react-lite";
import { modal } from "../Modal/ModalConnector";

interface IFileItem {
	item: IFile;
}

export const FileItem: React.FC<IFileItem> = observer(({ item }) => {
	const intl = useIntl();
	const { storeUser } = useContext(Context);
	const selectElement = useRef<any>(null);
	const [isOpenDelete, setIsOpenDelete] = useState<boolean>(false);

	useEffect(() => {
		const listener = (e) => {
			if (selectElement.current && !selectElement.current.contains(e.target)) {
				setIsOpenDelete(false);
			}
		};

		window.addEventListener("click", listener);

		return () => {
			window.removeEventListener("click", listener);
		};
	}, []);

	return (
		<div className={styles.fileItem}>
			<div className={styles.itemTop}>
				<div>
					{
						storeUser.objects.find(
							(i) =>
								i.address.containerId === item.container &&
								i.address.objectId === item.object
						)?.name
					}
				</div>
				<div
					className={styles.image}
					onClick={() => {
						setIsOpenDelete(!isOpenDelete);
					}}
					ref={selectElement}
				>
					<img src={points} alt="" />
				</div>
			</div>
			<img
				src={
					storeUser.objects
						.find(
							(i) =>
								i.address.containerId === item.container &&
								i.address.objectId === item.object
						)
						?.name.toLowerCase()
						.includes("jpeg") ||
					storeUser.objects
						.find(
							(i) =>
								i.address.containerId === item.container &&
								i.address.objectId === item.object
						)
						?.name.toLowerCase()
						.includes("png") ||
					storeUser.objects
						.find(
							(i) =>
								i.address.containerId === item.container &&
								i.address.objectId === item.object
						)
						?.name.toLowerCase()
						.includes("svg") ||
					storeUser.objects
						.find(
							(i) =>
								i.address.containerId === item.container &&
								i.address.objectId === item.object
						)
						?.name.toLowerCase()
						.includes("jpg") ||
					storeUser.objects
						.find(
							(i) =>
								i.address.containerId === item.container &&
								i.address.objectId === item.object
						)
						?.name.toLowerCase()
						.includes("ico") ||
					storeUser.objects
						.find(
							(i) =>
								i.address.containerId === item.container &&
								i.address.objectId === item.object
						)
						?.name.toLowerCase()
						.includes("eps") ||
					storeUser.objects
						.find(
							(i) =>
								i.address.containerId === item.container &&
								i.address.objectId === item.object
						)
						?.name.toLowerCase()
						.includes("bmp") ||
					storeUser.objects
						.find(
							(i) =>
								i.address.containerId === item.container &&
								i.address.objectId === item.object
						)
						?.name.toLowerCase()
						.includes("tiff") ||
					storeUser.objects
						.find(
							(i) =>
								i.address.containerId === item.container &&
								i.address.objectId === item.object
						)
						?.name.toLowerCase()
						.includes("webp")
						? `https://neofs.neobox.world/${item.container}/${item.object}`
						: filesImage
				}
				alt=""
				onClick={() =>
					window.open(
						`https://neofs.neobox.world/${item.container}/${item.object}`
					)
				}
				className={styles.itemImage}
			/>
			{isOpenDelete && (
				<div className={styles.deleteWindow}>
					<div
						className={styles.deleteWindowItem}
						onClick={() =>
							window.open(
								`https://neofs.neobox.world/${item.container}/${item.object}`
							)
						}
					>
						<img src={openIcon} alt="" />
						<div>{intl.formatMessage({ id: "Open" })}</div>
					</div>
					<div
						className={styles.deleteWindowItem}
						onClick={() =>
							modal.open(
								<ShareFile
									link={`https://neofs.neobox.world/${item.container}/${item.object}`}
								/>,
								{ className: "none" }
							)
						}
					>
						<img src={shareIcon} alt="" />
						<div>{intl.formatMessage({ id: "Share" })}</div>
					</div>
					<div
						className={styles.deleteWindowItem}
						onClick={() => {
							modal.open(<DeleteFile object={item.object} />, {
								className: "none",
							});
							setIsOpenDelete(!isOpenDelete);
						}}
					>
						<img src={deleteIcon} alt="" />
						<div>{intl.formatMessage({ id: "Delete" })}</div>
					</div>
				</div>
			)}
		</div>
	);
});
