import React, { InputHTMLAttributes, useEffect } from "react";
import cl from "classnames";
import styles from "./InputAuth.module.scss";
import { useIntl } from "react-intl";
import { showHidePassword, showHidePasswords } from "src/utils/hidePassword";

interface IInputRProps extends InputHTMLAttributes<HTMLInputElement> {
	value: string;
	label?: string;
	style?: React.CSSProperties;
	setValue: React.Dispatch<React.SetStateAction<string>>;
	setError?: React.Dispatch<React.SetStateAction<React.ReactNode | any>>;
	regExp?: RegExp;
	customReg?: RegExp;
	textError?: React.ReactNode | any;
	disabled?: boolean;
	statusIcon?: "error" | "done" | "waiting";
	type?: string;
	id?: string;
	borderNone?: boolean;
}

export const InputAuth: React.FC<IInputRProps> = ({
	setValue,
	setError,
	textError,
	value,
	style,
	label,
	regExp,
	customReg,
	statusIcon,
	disabled,
	type,
	id,
	borderNone,
	...props
}) => {
	const intl = useIntl();

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (customReg) {
			setValue(e.target.value.replace(customReg, ""));
		} else {
			setValue(e.target.value.slice(0, 25));
		}
	};
	useEffect(() => {
		if (setError && regExp) {
			if (regExp.test(value)) {
				setError(null);
			} else {
				setError(textError);
			}
		}
	}, [value]);

	return (
		<div className={styles.inputBlock} id="inputs">
			<label htmlFor="input">{intl.formatMessage({ id: label })}</label>
			<input
				className={cl(styles.input, { [styles.disabled]: disabled })}
				id={type === "password" && !id ? "passwordInput" : id}
				onChange={onChange}
				value={value}
				style={style}
				disabled={disabled}
				type={type}
				{...props}
			/>
			{type === "password" && (
				<div
					id={"passwordControl"}
					className={styles.passwordControl}
					onClick={id ? () => showHidePasswords(id) : () => showHidePassword()}
				></div>
			)}
		</div>
	);
};
